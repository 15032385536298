import { AchievementData } from "./base_types";

const LOCAL_STORAGE_VERSION = '1'

export class DataLoader {

    public static getAchievementData(): AchievementData {

        if(!localStorage.getItem('wordMarshStorageVersion')){
            this.initializeStorage()
        }

        return {
            highScore: parseInt(localStorage.getItem('highScore') || '0',10),
            highestPercentage: parseInt(localStorage.getItem('highestPercentage') || '0',10),
            longestWord: localStorage.getItem('longestWord') || 'n/a',
            highestTotalWords: parseInt(localStorage.getItem('highestTotalWords') || '0', 10),
            gamesPlayed: parseInt(localStorage.getItem('gamesPlayed') || '0',10)
        }
    } 

    public static initializeStorage(): void {
        localStorage.setItem('wordMarshStorageVersion', LOCAL_STORAGE_VERSION)
        localStorage.setItem('highScore','0')
        localStorage.setItem('highestPercentage','0')
        localStorage.setItem('longestWord','n/a')
        localStorage.setItem('highestTotalWords', '0')
        localStorage.setItem('gamesPlayed','0')
    }

    public static setHighScore(score: number): void {
        localStorage.setItem('highScore', score.toString())
    }

    public static setHighestPercentage(percent: number) :void {
        localStorage.setItem('highestPercentage', percent.toString())
    }

    public static setLongestWord(word: string): void {
        localStorage.setItem('longestWord', word)
    }

    public static setHighestTotalWords(totalWords: number): void {
        localStorage.setItem('highestTotalWords', totalWords.toString())
    }

    public static setGamesPlayed(gamesPlayed: number): void {
        localStorage.setItem('gamesPlayed', gamesPlayed.toString())
    }

    public static getDailyWords(identifier: string): string[] {
        let dailyIdentifier = "daily-" + identifier
        if(!localStorage.getItem(dailyIdentifier)) return []
        // @ts-ignore
        return localStorage.getItem(dailyIdentifier)?.split(',').filter(Boolean)
    }

    public static saveDailyWord(identifier : string, word : string): void {
        let dailyIdentifier = "daily-" + identifier
        let currentValue = ''
        if(localStorage.getItem(dailyIdentifier)){
            // @ts-ignore
            currentValue = localStorage.getItem(dailyIdentifier)
        }
        localStorage.setItem(dailyIdentifier, currentValue + word + ',')
    }
}