<template>
    <div :class="keyboardClass"></div>
  </template>
  
  <script>
  import { defineComponent } from 'vue'
  import Keyboard from "simple-keyboard";
  import "simple-keyboard/build/css/index.css";
  
  export default defineComponent({
    name: "SimpleKeyboard",
    props: {
      keyboardClass: {
        default: "simple-keyboard",
        type: String
      },
      input: {
        type: String
      }
    },
    data: () => ({
      keyboard: null
    }),
    mounted() {
      this.keyboard = new Keyboard(this.keyboardClass, {
        onChange: this.onChange,
        onKeyPress: this.onKeyPress,
        layout:{
            'default': [
                'q w e r t y u i o p',
                'a s d f g h j k l',
                'z x c v b n m {bksp}',
                '{enter}'
            ],
        },
        display: {
            '{enter}': "Submit",
            '{bksp}': 'delete',
        }
      });
    },
    methods: {
      onChange(input) {
        this.$emit("onChange", input);
      },
      onKeyPress(button) {
        this.$emit("onKeyPress", button);
  
        /**
         * If you want to handle the shift and caps lock buttons
         */
        if (button === "{shift}" || button === "{lock}") this.handleShift();
      },
      handleShift() {
        let currentLayout = this.keyboard.options.layoutName;
        let shiftToggle = currentLayout === "default" ? "shift" : "default";
  
        this.keyboard.setOptions({
          layoutName: shiftToggle
        });
      }
    },
    watch: {
      input(input) {
        this.keyboard.setInput(input);
      }
    }
  });
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style>
  .hg-theme-default .hg-button {
    height: 65px;
  }
  .simple-keyboard {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
  }
  </style>
  