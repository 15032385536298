// import { ENABLE_WORD_LIST } from './enable_word_list'
import { WORD_LIST } from './word_list'
import { WordGrid, SpecialCharacters } from './grid'
import { VOWELS, CONSONANTS, ALL_LETTERS } from './word_tools'
import { GridOptions } from './base_types'

export type GameOptions = {
  minutes: number,
  minWordLength: number,
  autoRotate: boolean,
  useTimer: boolean,
  gridOptions: GridOptions,
  gameMode: GameMode
}

export function serializeGameOptions(options: GameOptions): string{
  const optionString = JSON.stringify(options)
  return btoa(optionString)
}

export function deserializeGameOptions(optionString: string): GameOptions{
  const jsonString = atob(optionString)
  console.log(jsonString)
  return JSON.parse(jsonString)
}

export function copyTextToClipboard(text: string): void {
  navigator.clipboard.writeText(text).then(function() {
    console.log('Async: Copying to clipboard was successful!');
  }, function(err) {
    console.error('Async: Could not copy text: ', err);
  });
}

export enum GameMode {
  STANDARD = 'STANDARD',
  MULTIPLAYER = 'MULTIPLAYER',
  TRADITIONAL = 'TRADITIONAL'
}

export enum GameState {
  NOT_STARTED = 'NOT_STARTED',
  RUNNING = 'RUNNING',
  FINISHED = 'FINISHED'
}

export enum GameStateNew {
  RUNNING = 'RUNNING',
  BREAK = 'BREAK',
  INITIALIZING = 'INITIALIZING'
}

const VISITED_CHAR = '#'

export function checkValidWord(word : string): boolean {
  return WORD_LIST.includes(word);
}

export function listOfValidWordsOld(grid: WordGrid): String[] {
  let wordList : string[] = []
  for(let i=0;i<WORD_LIST.length;i++){
    if(grid.validateWord(WORD_LIST[i])) wordList.push(WORD_LIST[i])
  }

  return wordList
}


const LETTER_TREE = createLetterTree()

export function listOfValidWords(grid: WordGrid, minWordLength: number): String[] {
  let wordList: string[] = []
  
  for(let i=0; i< grid.getGrid().length; i++){
    for(let j = 0; j < grid.getGrid()[i].length; j++){
      let workingGrid = grid.cloneGrid()
      checkGrid(i, j, '', workingGrid, wordList, minWordLength, LETTER_TREE)
    }
  }

  return wordList
}


function evaluateNewNode(height: number, width: number, currentChar: string, currentWord:string, workingGrid: string[][], wordList: string[], minWordLength: number, node: any){
  currentWord += currentChar

  if(node.word && currentWord.length >= minWordLength) {
    if(!wordList.includes(currentWord)) wordList.push(currentWord)
  }
  workingGrid[height][width] = VISITED_CHAR

  // Top
  checkGrid(height + 1, width, currentWord, workingGrid, wordList, minWordLength, node)
  checkGrid(height + 1, width + 1, currentWord, workingGrid, wordList, minWordLength, node)
  checkGrid(height + 1, width - 1, currentWord, workingGrid, wordList, minWordLength, node)

  // Left/Right
  checkGrid(height, width + 1, currentWord, workingGrid, wordList, minWordLength, node)
  checkGrid(height, width - 1, currentWord, workingGrid, wordList, minWordLength, node)

  // Bottom
  checkGrid(height - 1, width, currentWord, workingGrid, wordList, minWordLength, node)
  checkGrid(height - 1, width + 1, currentWord, workingGrid, wordList, minWordLength, node)
  checkGrid(height - 1, width - 1, currentWord, workingGrid, wordList, minWordLength, node)

  workingGrid[height][width] = currentChar
}

function checkGrid(height: number, width: number, currentWord:string, workingGrid: string[][], wordList: string[], minWordLength: number, node: any): void {
  
  if(height < 0 || height >= workingGrid.length) return
  if(width < 0 || width >= workingGrid[height].length) return
  
  let currentChar = workingGrid[height][width].toLowerCase()

  if(currentChar == VISITED_CHAR) return

  let newNode;

  if(WordGrid.isWildChar(currentChar)){
    let wildCharList = ALL_LETTERS
    if(currentChar == SpecialCharacters.WILD_VOWEL) wildCharList = VOWELS
    if(currentChar == SpecialCharacters.WILD_CONSONANT) wildCharList = CONSONANTS

    for(let i=0;i<wildCharList.length;i++){
      let wildChar = wildCharList[i].toLowerCase()
      if(node[wildChar] != undefined){
        newNode = node[wildChar]
        evaluateNewNode(
          height, width, wildChar, currentWord, workingGrid, wordList, minWordLength, newNode
        )
      }
    }
    return
  }

  if(currentChar.length === 1){
    if(node[currentChar] === undefined) return
    newNode = node[currentChar]
  }
  else if (currentChar.length === 2){
    if(node[currentChar.charAt(0)] === undefined) return
    let nextNode = node[currentChar.charAt(0)]

    if(nextNode[currentChar.charAt(1)] === undefined) return
    newNode = nextNode[currentChar.charAt(1)]
  }
  else {
    console.error('Unexpected cell found in grid: ' + currentChar)
    return
  }

  evaluateNewNode(
    height, width, currentChar, currentWord, workingGrid, wordList, minWordLength, newNode
  )
}


export function createLetterTree(): object{
  let tree = {}

  for(let i=0;i<WORD_LIST.length;i++){
    addWordToTree(tree, WORD_LIST[i])
  }

  return tree
}

function addWordToTree(tree: any, word: string): void {
  let char = word.charAt(0);
  if(tree[char] === undefined) tree[char] = { word: false }
  if(word.length === 1){
    tree[char].word = true
  }
  else {
    addWordToTree(tree[char], word.substring(1))
  }
}


export type ScoreSummary = {
  totalScore: number,
  threeLetterWords: number,
  fourLetterWords: number,
  fiveLetterWords: number,
  sixLetterWords: number,
  sevenLetterWords: number,
  eightPlusLetterWords: number,
  longestWord: string,
  totalWords: number
}

const scoringRules = {
  3 : 1,
  
}

export const pointsByWordLength = function(wordLength: number) : number {
  if(wordLength === 3 || wordLength === 4) return 1
  if(wordLength === 5) return 2
  if(wordLength === 6) return 3
  if(wordLength === 7) return 5
  if(wordLength >= 8) return 11
  console.error('Received unexpected word length: ' + wordLength)
  return -1000
}

// 3-letter words and 4-letter words are 1 point each.
// 5-letter words are 2 points each.
// 6-letter words are 3 points each.
// 7-letter words are 5 points each.
// 8-letter words and longer are 11 points each.

export function calculateScore(wordList: string[]): ScoreSummary {
  const threeLetterWords = wordList.filter((word) => { return word.length == 3 }).length
  const fourLetterWords = wordList.filter((word) => { return word.length == 4}).length
  const fiveLetterWords = wordList.filter((word) => { return word.length == 5}).length
  const sixLetterWords = wordList.filter((word) => { return word.length == 6}).length
  const sevenLetterWords = wordList.filter((word) => { return word.length == 7}).length
  const eightPlusLetterWords = wordList.filter((word) => { return word.length >= 8}).length

  const totalScore = threeLetterWords + fourLetterWords + fiveLetterWords * 2 + sixLetterWords * 3 + sevenLetterWords * 5 + eightPlusLetterWords * 11

  const totalScoreNew = wordList.reduce((acc, curr) : number => {
    return acc + pointsByWordLength(curr.length)
  }, 0)

  console.log('Total Score, old vs new: ' + totalScore + " ," + totalScoreNew)

  const longestWord = wordList.reduce((acc, curr): string => {
    if(curr.length > acc.length) return curr
    return acc
  }, '')
  
  let scoreObj = {
    totalScore,
    threeLetterWords,
    fourLetterWords,
    fiveLetterWords,
    sixLetterWords,
    sevenLetterWords,
    eightPlusLetterWords,
    longestWord,
    totalWords: wordList.length
  }
  console.log(scoreObj)
  return scoreObj
}