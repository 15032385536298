import { calculateScore } from "./game"
import { AchievementData } from "./base_types"
import { DataLoader } from "./data_layer"

export function getAchievementData(): AchievementData {
    const achievementData = DataLoader.getAchievementData()
    return achievementData
}

export function updateAchievementData(userWords: string[], allValidWords: String[]): void {

    // No words mean probably not a completed game - don't record results
    if(userWords.length === 0) return

    const achievementData = DataLoader.getAchievementData()

    let scoreSummary = calculateScore(userWords)
    if(scoreSummary.totalScore > achievementData.highScore) DataLoader.setHighScore(scoreSummary.totalScore)

    let percentage = Math.ceil((userWords.length/allValidWords.length)*100)
    if(percentage > achievementData.highestPercentage) DataLoader.setHighestPercentage(percentage)

    const longestWord = userWords.reduce((acc, curr) => {
        if(curr.length > acc.length) return curr
        return acc
    },'')
    if(longestWord.length > achievementData.longestWord.length) DataLoader.setLongestWord(longestWord)
    
    if(userWords.length > achievementData.highestTotalWords) DataLoader.setHighestTotalWords(userWords.length)

    DataLoader.setGamesPlayed(achievementData.gamesPlayed + 1)
}