
import { Difficulty, NewDifficulty, DoubleCounts, DoubleOptions } from './base_types';
import { VOWELS, CONSONANTS, ALL_LETTERS } from './word_tools';


export const LETTER_CONFIG_EASY = {
  // Vowels
  'A' : 7,
  'E' : 8,
  'I' : 6,
  'O' : 6,
  'U' : 5,
  
  // Consonants
  'B' : 6,
  'C' : 5,
  'D' : 6,
  'F' : 4,
  'G' : 5,
  'H' : 5,
  'J' : 3,
  'K' : 2,
  'L' : 5,
  'M' : 7,
  'N' : 6,
  'P' : 4,
  'Qu' : 0,
  'R' : 6,
  'S' : 8,
  'T' : 7,
  'V' : 0,
  'W' : 1,
  'X' : 0,
  'Y' : 2,
  'Z' : 0,

  // Default Double Letters
  'Th' : 1,
  'Sh' : 1,
  'Ch' : 1,
  'Ee' : 1,
  'Oo' : 1,
};

export const LETTER_CONFIG_STANDARD = {
  'A' : 6,
  'E' : 7,
  'I' : 5,
  'O' : 5,
  'U' : 4,
  
  'B' : 4,
  'C' : 4,
  'D' : 4,
  'F' : 2,
  'G' : 3,
  'H' : 4,
  'J' : 3,
  'K' : 2,
  'L' : 4,
  'M' : 4,
  'N' : 4,
  'P' : 3,
  'Qu' : 1,
  'R' : 5,
  'S' : 5,
  'T' : 5,
  'V' : 1,
  'W' : 2,
  'X' : 1,
  'Y' : 2,
  'Z' : 1,

  // Default Double Letters
  'Th' : 1,
  'Sh' : 1,
  'Ch' : 1,
  'Ee' : 1,
  'Oo' : 1,
};


export const LETTER_CONFIG_HARD = {
  // Vowels
  'A' : 6,
  'E' : 6,
  'I' : 6,
  'O' : 7,
  'U' : 6,
  
  // Constants
  'B' : 4,
  'C' : 4,
  'D' : 4,
  'F' : 4,
  'G' : 6,
  'H' : 5,
  'J' : 3,
  'K' : 6,
  'L' : 4,
  'M' : 3,
  'N' : 3,
  'P' : 6,
  'Qu' : 2,
  'R' : 5,
  'S' : 5,
  'T' : 5,
  'V' : 3,
  'W' : 2,
  'X' : 3,
  'Y' : 3,
  'Z' : 2,

  // Default Double Letters
  'Ch' : 2,
};

export const LETTER_CONFIG_IMPOSSIBLE = {
  'A' : 5,
  'E' : 5,
  'I' : 5,
  'O' : 5,
  'U' : 6,
  
  'B' : 3,
  'C' : 3,
  'D' : 3,
  'F' : 3,
  'G' : 6,
  'H' : 4,
  'J' : 4,
  'K' : 7,
  'L' : 3,
  'M' : 2,
  'N' : 2,
  'P' : 6,
  'Qu' : 2,
  'R' : 4,
  'S' : 3,
  'T' : 3,
  'V' : 5,
  'W' : 4,
  'X' : 3,
  'Y' : 5,
  'Z' : 4,
};

// const difficultyToConfigMap = {
//   // Easy
//  [NewDifficulty.EASIEST] : LETTER_CONFIG_EASY,
//  [NewDifficulty.EASY] : LETTER_CONFIG_EASY,
//  [NewDifficulty.VERYEASY] : LETTER_CONFIG_EASY,

//  // Standard
//  [NewDifficulty.AVERAGE] : LETTER_CONFIG_STANDARD,
//  [NewDifficulty.ABOVEAVERAGE] : LETTER_CONFIG_STANDARD,
//  [NewDifficulty.MODERATE] : LETTER_CONFIG_STANDARD,

//   // HARD
//   [NewDifficulty.HARD] : LETTER_CONFIG_HARD,
//   [NewDifficulty.VERYHARD] : LETTER_CONFIG_HARD,

//   // VERY HARD
//   [NewDifficulty.ARDOUS] : LETTER_CONFIG_IMPOSSIBLE,
//   [NewDifficulty.IMPOSSIBLE] : LETTER_CONFIG_IMPOSSIBLE,

// }

const difficultyToConfigMap = {
  [Difficulty.EASY] : LETTER_CONFIG_EASY,
  [Difficulty.STANDARD] : LETTER_CONFIG_STANDARD,
  [Difficulty.HARD] : LETTER_CONFIG_HARD,
  [Difficulty.IMPOSSIBLE] : LETTER_CONFIG_IMPOSSIBLE
}

function randomArrayElement(arr : any[]): any {
  return arr[Math.floor(Math.random() * arr.length)]
}

export class LetterConfig {

  static DOUBLES_TO_ADD = 6

  static getConfigArray(configObject: any) : string[] {
    const configArray = []
    for (const key in configObject) {
      const iterations = configObject[key]
      for(let i=0; i<iterations;i++) configArray.push(key)
    }

    return configArray
  }

  static addDoubles(doubleCount : DoubleCounts, doubleOption : DoubleOptions, configArray: Array<string>){

    if(doubleCount === DoubleCounts.NONE) return configArray
    

    for(let i = 0; i < doubleCount; i++) {

      let newDouble = ''

      if(doubleOption === DoubleOptions.VOWELCONSONANT){
        newDouble = randomArrayElement([
          randomArrayElement(VOWELS) + randomArrayElement(CONSONANTS),
          randomArrayElement(CONSONANTS) + randomArrayElement(VOWELS)
        ])
      }
      else if (doubleOption === DoubleOptions.ALLLETTERS){
        newDouble = randomArrayElement(ALL_LETTERS) + randomArrayElement(ALL_LETTERS)
      }
      else if (doubleOption === DoubleOptions.CONSONANTS){
        newDouble = randomArrayElement(CONSONANTS) + randomArrayElement(CONSONANTS)
      }

      newDouble = newDouble[0] + newDouble[1].toLowerCase()
      configArray.push(newDouble)
    }
    return configArray
  }

  static getLetterConfig(difficulty: Difficulty): string[]{
    let letterConfig = difficultyToConfigMap[difficulty]

    return LetterConfig.getConfigArray(letterConfig);
  }

  static getLetterConfigWithDoubles(difficulty: Difficulty, doubleCount : DoubleCounts, doubleOption : DoubleOptions){

    let letterConfig = difficultyToConfigMap[difficulty]

    let configArray = LetterConfig.getConfigArray(letterConfig);

    configArray = this.addDoubles(doubleCount, doubleOption, configArray)

    return configArray
  }
}
