export enum EmptySquareOptions {
  NONE = 'NONE',
  ONE = 'ONE',
  TWO = 'TWO',
  THREE = 'THREE',
  SEVERAL = 'SEVERAL',
  MANY = 'MANY',
  CHAOS = 'CHAOS'
}

export enum DoubleOptions {
  NONE,
  VOWELCONSONANT,
  ALLLETTERS,
  CONSONANTS
}

export enum DoubleCounts {
  NONE = 0,
  FEW = 5,
  MANY = 10,
  TONS = 20,
}


export enum Difficulty {
  EASY = 'EASY',
  STANDARD = 'STANDARD',
  HARD = 'HARD',
  IMPOSSIBLE = 'IMPOSSIBLE'
}

export enum NewDifficulty {
  EASIEST = 'EASIEST',
  VERYEASY = 'VERY EASY',
  EASY = 'EASY',

  // Medium/Standard
  AVERAGE = 'AVERAGE',
  ABOVEAVERAGE = 'ABOVE AVERAGE',
  MODERATE = 'MODERATE',

  // Hard
  // CHALLENGING = 'CHALLENGING',
  // VERYCHALLENGING = 'VERY CHALLENGING',
  HARD = 'HARD',
  VERYHARD = 'VERY HARD',
  
  // Very Hard
  ARDOUS = 'ARDOUS',
  IMPOSSIBLE = 'IMPOSSIBLE'
}

export type GridTemplate = string[]

export enum TemplateStrings {
  VOWEL = 'V',
  CONSONANT = 'C',
  ANY_LETTER = 'A',
  EMPTY = 'X',
  DOUBLE = 'D'
}

export interface GridOptions  {
  width: number
  height: number
  difficulty: Difficulty,
  emptySquares: EmptySquareOptions,
  useWilds: boolean,
  useSpecials: boolean,
  seed?: number
  doubleCount : DoubleCounts,
  doubleOption: DoubleOptions,
  title: string,
  description?: string
  template?: GridTemplate
  // specialChars?: SpecialCharacterOptions
}

export interface AchievementData {
  highScore: number,
  highestPercentage: number,
  longestWord: string,
  highestTotalWords: number,
  gamesPlayed: number
}