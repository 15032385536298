
export const VOWELS = ['A','E','I','O','U']

export function isVowel(char: string) : boolean {
  if(char === 'A' || char === 'E' || char === 'I' || char === 'O' || char === 'U') return true
  return false
}

export const CONSONANTS = ['B','C','D','F','G','H','J','K','L','M','N','P','Q','R','S','T','V','W','X','Y','Z']

export const EASY_CONSONANTS = ['B','C','D','F','G','H','L','M','N','P','R','S','S','T','T','W']
export const STANDARD_CONSONANTS = ['B','C','D','F','G','H','J','K','L','M','N','P','R','S','S','T','T','V','W','Y']
export const HARD_CONSONANTS = ['B','C','D','F','G','H','J','K','L','M','N','P','Qu','R','S','T','V','W','X','Y','Z']

export function isConsonant(char: string) : boolean {
  if(CONSONANTS.includes(char)) return true
  return false
}

export const ALL_LETTERS = CONSONANTS.concat(VOWELS)
