import { GridTemplate } from "./base_types";

export const BASE_TEMPLATES = {
    CORNERS: [
        'XAAX',
        'AAAA',
        'AAAA',
        'XAAX'
    ],
    SMALL_CENTER: [
        'AAA',
        'AVA',
        'AAA'
    ],
    SPIDERWEB: [
        'CCCCCCC',
        'XVXVXVX',
        'CCCCCCC',
        'XVXVXVX',
    ],
    NO_CENTER: [
        'AAAAAA',
        'AAXXAA',
        'AAXXAA',
        'AAAAAA',
    ],
    WINDOW: [
        'AAVAA',
        'AXAXA',
        'VAVAV',
        'AXAXA',
        'AAVAA'
    ],
    TRIANGLE: [
        'XXXXA',
        'XXXAA',
        'XXAAA',
        'XAAAA',
        'AAAAA'
    ],
    SPLIT: [
        'AAXAA',
        'AAXAA',
        'AAVAA',
        'AAXAA',
        'AAXAA'
    ],
    QUADS: [
        'AAXAA',
        'AAXCA',
        'XXVXX',
        'ACXAA',
        'AAXAA'
    ],
    SMALL_WINDOW: [
        'CACAC',
        'VXVXV',
        'CACAC',
    ],
    INTERSECTION: [

        'AAXXX',
        'AAAAA',
        'AAAAA',
        'AAXXX',

    ],
    CHECKERS_SMALL: [
        'AAXXAAXX',
        'AVXXCVXX',
        'XXCVXXCA',
        'XXACXXAV',
        'AVXXAVXX',
        'AAXXAAXX',
    ],
    TIE_FIGHTER: [
        'AVA',
        'XCX',
        'AVA',
        'AAA',
        'AAA',
        'XCX',
        'AVA'
    ],
    OVERLAP: [
        'AAAXX',
        'AVAXX',
        'AACAA',
        'XXAVA',
        'XXAAA'
    ]

}