import { EmptySquareOptions, DoubleCounts, DoubleOptions, Difficulty, GridTemplate } from "./base_types"
import { BASE_TEMPLATES } from "./template_lib"


export interface gameConfiguration {
    title: string,
    difficulty: Difficulty,
    description? : string,
    maxHeight: number,
    minHeight: number,
    maxWidth: number,
    minWidth: number,
    emptySquares: EmptySquareOptions,
    // Number of doubles injected into the array used to generate grid - not a guarantee.  
    // Higher number, higher chance of getting more doubles!
    doubleCount: DoubleCounts,
    doubleOption: DoubleOptions,
    template?: GridTemplate
  }
  
  
export const gameConfigurationsStandard : gameConfiguration[] = [
    {
        title: "Overlap",
        difficulty: Difficulty.STANDARD,
        description : 'Enough words to write a novel',
        maxHeight: 2,
        minHeight: 2,
        maxWidth: 8,
        minWidth: 7,
        emptySquares: EmptySquareOptions.NONE,
        doubleCount: DoubleCounts.NONE,
        doubleOption: DoubleOptions.NONE,
        template: BASE_TEMPLATES.OVERLAP
    },
    {
        title: "Tie Fighter",
        difficulty: Difficulty.STANDARD,
        description : 'Enough words to write a novel',
        maxHeight: 2,
        minHeight: 2,
        maxWidth: 8,
        minWidth: 7,
        emptySquares: EmptySquareOptions.NONE,
        doubleCount: DoubleCounts.NONE,
        doubleOption: DoubleOptions.NONE,
        template: BASE_TEMPLATES.TIE_FIGHTER
    },
    {
        title: "Checkerboard",
        difficulty: Difficulty.STANDARD,
        description : 'Enough words to write a novel',
        maxHeight: 2,
        minHeight: 2,
        maxWidth: 8,
        minWidth: 7,
        emptySquares: EmptySquareOptions.NONE,
        doubleCount: DoubleCounts.NONE,
        doubleOption: DoubleOptions.NONE,
        template: BASE_TEMPLATES.CHECKERS_SMALL
    },
    {
        title: "Intersection",
        difficulty: Difficulty.STANDARD,
        description : 'Enough words to write a novel',
        maxHeight: 2,
        minHeight: 2,
        maxWidth: 8,
        minWidth: 7,
        emptySquares: EmptySquareOptions.NONE,
        doubleCount: DoubleCounts.NONE,
        doubleOption: DoubleOptions.NONE,
        template: BASE_TEMPLATES.INTERSECTION
    },
    {
        title: "Split Decision",
        difficulty: Difficulty.STANDARD,
        description : 'Enough words to write a novel',
        maxHeight: 2,
        minHeight: 2,
        maxWidth: 8,
        minWidth: 7,
        emptySquares: EmptySquareOptions.NONE,
        doubleCount: DoubleCounts.NONE,
        doubleOption: DoubleOptions.NONE,
        template: BASE_TEMPLATES.SPLIT
    },
    {
        title: "Triangle",
        difficulty: Difficulty.STANDARD,
        description : 'Enough words to write a novel',
        maxHeight: 2,
        minHeight: 2,
        maxWidth: 8,
        minWidth: 7,
        emptySquares: EmptySquareOptions.NONE,
        doubleCount: DoubleCounts.NONE,
        doubleOption: DoubleOptions.NONE,
        template: BASE_TEMPLATES.TRIANGLE
    },
    {
        title: "No Center",
        difficulty: Difficulty.STANDARD,
        description : 'Enough words to write a novel',
        maxHeight: 2,
        minHeight: 2,
        maxWidth: 8,
        minWidth: 7,
        emptySquares: EmptySquareOptions.NONE,
        doubleCount: DoubleCounts.NONE,
        doubleOption: DoubleOptions.NONE,
        template: BASE_TEMPLATES.NO_CENTER
    },
    {
        title: "Window Panes",
        difficulty: Difficulty.STANDARD,
        description : 'Enough words to write a novel',
        maxHeight: 2,
        minHeight: 2,
        maxWidth: 8,
        minWidth: 7,
        emptySquares: EmptySquareOptions.NONE,
        doubleCount: DoubleCounts.NONE,
        doubleOption: DoubleOptions.NONE,
        template: BASE_TEMPLATES.WINDOW
    },

    // Non-template Levels
    {
        title: "Chaos",
        difficulty: Difficulty.EASY,
        description : 'Enough words to write a novel',
        maxHeight: 5,
        minHeight: 5,
        maxWidth: 5,
        minWidth: 5,
        emptySquares: EmptySquareOptions.CHAOS,
        doubleCount: DoubleCounts.NONE,
        doubleOption: DoubleOptions.NONE
    },
    {
        title: "The Maze",
        difficulty: Difficulty.EASY,
        description : 'Enough words to write a novel',
        maxHeight: 6,
        minHeight: 6,
        maxWidth: 6,
        minWidth: 6,
        emptySquares: EmptySquareOptions.MANY,
        doubleCount: DoubleCounts.NONE,
        doubleOption: DoubleOptions.NONE
    },
    {
        title: "Double Wide",
        difficulty: Difficulty.STANDARD,
        description : 'Enough words to write a novel',
        maxHeight: 2,
        minHeight: 2,
        maxWidth: 7,
        minWidth: 7,
        emptySquares: EmptySquareOptions.NONE,
        doubleCount: DoubleCounts.NONE,
        doubleOption: DoubleOptions.VOWELCONSONANT
    },
    {
        title: 'Classic',
        difficulty: Difficulty.STANDARD,
        description : 'Enough words to write a novel',
        maxHeight: 4,
        minHeight: 4,
        maxWidth: 4,
        minWidth: 4,
        emptySquares: EmptySquareOptions.NONE,
        doubleCount: DoubleCounts.NONE,
        doubleOption: DoubleOptions.CONSONANTS
    },
    {
        title: 'Classic (Hard)',
        difficulty: Difficulty.HARD,
        description : 'Enough words to write a novel',
        maxHeight: 4,
        minHeight: 4,
        maxWidth: 4,
        minWidth: 4,
        emptySquares: EmptySquareOptions.ONE,
        doubleCount: DoubleCounts.NONE,
        doubleOption: DoubleOptions.CONSONANTS
    },
    {
        title: 'Tiny Grid',
        difficulty: Difficulty.EASY,
        description : 'Enough words to write a novel',
        maxHeight: 3,
        minHeight: 3,
        maxWidth: 3,
        minWidth: 3,
        emptySquares: EmptySquareOptions.NONE,
        doubleCount: DoubleCounts.NONE,
        doubleOption: DoubleOptions.VOWELCONSONANT
    },
]

export const gameConfigurationsDaily : gameConfiguration[] = [
    
    /** TEMPLATES */

    {
        title: "Overlap",
        difficulty: Difficulty.STANDARD,
        description : 'Enough words to write a novel',
        maxHeight: 2,
        minHeight: 2,
        maxWidth: 8,
        minWidth: 7,
        emptySquares: EmptySquareOptions.NONE,
        doubleCount: DoubleCounts.NONE,
        doubleOption: DoubleOptions.NONE,
        template: BASE_TEMPLATES.OVERLAP
    },
    {
        title: "Checkerboard",
        difficulty: Difficulty.STANDARD,
        description : 'Enough words to write a novel',
        maxHeight: 2,
        minHeight: 2,
        maxWidth: 8,
        minWidth: 7,
        emptySquares: EmptySquareOptions.NONE,
        doubleCount: DoubleCounts.NONE,
        doubleOption: DoubleOptions.NONE,
        template: BASE_TEMPLATES.CHECKERS_SMALL
    },
    {
        title: "Split Decision",
        difficulty: Difficulty.STANDARD,
        description : 'Enough words to write a novel',
        maxHeight: 2,
        minHeight: 2,
        maxWidth: 8,
        minWidth: 7,
        emptySquares: EmptySquareOptions.NONE,
        doubleCount: DoubleCounts.NONE,
        doubleOption: DoubleOptions.NONE,
        template: BASE_TEMPLATES.SPLIT
    },
    {
        title: "Triangle",
        difficulty: Difficulty.STANDARD,
        description : 'Enough words to write a novel',
        maxHeight: 2,
        minHeight: 2,
        maxWidth: 8,
        minWidth: 7,
        emptySquares: EmptySquareOptions.NONE,
        doubleCount: DoubleCounts.NONE,
        doubleOption: DoubleOptions.NONE,
        template: BASE_TEMPLATES.TRIANGLE
    },
    {
        title: "No Center",
        difficulty: Difficulty.STANDARD,
        description : 'Enough words to write a novel',
        maxHeight: 2,
        minHeight: 2,
        maxWidth: 8,
        minWidth: 7,
        emptySquares: EmptySquareOptions.NONE,
        doubleCount: DoubleCounts.NONE,
        doubleOption: DoubleOptions.NONE,
        template: BASE_TEMPLATES.NO_CENTER
    },
    {
        title: "Window Panes",
        difficulty: Difficulty.STANDARD,
        description : 'Enough words to write a novel',
        maxHeight: 2,
        minHeight: 2,
        maxWidth: 8,
        minWidth: 7,
        emptySquares: EmptySquareOptions.NONE,
        doubleCount: DoubleCounts.NONE,
        doubleOption: DoubleOptions.NONE,
        template: BASE_TEMPLATES.WINDOW
    },

    // Non-template Levels
    {
        title: "Chaos",
        difficulty: Difficulty.EASY,
        description : 'Enough words to write a novel',
        maxHeight: 6,
        minHeight: 5,
        maxWidth: 6,
        minWidth: 5,
        emptySquares: EmptySquareOptions.CHAOS,
        doubleCount: DoubleCounts.NONE,
        doubleOption: DoubleOptions.NONE
    },
    {
        title: "The Maze",
        difficulty: Difficulty.EASY,
        description : 'Enough words to write a novel',
        maxHeight: 6,
        minHeight: 6,
        maxWidth: 6,
        minWidth: 6,
        emptySquares: EmptySquareOptions.MANY,
        doubleCount: DoubleCounts.NONE,
        doubleOption: DoubleOptions.NONE
    },
    {
        title: "Double Wide",
        difficulty: Difficulty.STANDARD,
        description : 'Enough words to write a novel',
        maxHeight: 3,
        minHeight: 3,
        maxWidth: 7,
        minWidth: 7,
        emptySquares: EmptySquareOptions.NONE,
        doubleCount: DoubleCounts.NONE,
        doubleOption: DoubleOptions.VOWELCONSONANT
    },
    {
        title: 'Giant Grid',
        difficulty: Difficulty.STANDARD,
        description : 'Enough words to write a novel',
        maxHeight: 6,
        minHeight: 6,
        maxWidth: 6,
        minWidth: 6,
        emptySquares: EmptySquareOptions.NONE,
        doubleCount: DoubleCounts.NONE,
        doubleOption: DoubleOptions.VOWELCONSONANT
    },
    {
        title: 'Big Grid',
        difficulty: Difficulty.STANDARD,
        description : 'Enough words to write a novel',
        maxHeight: 5,
        minHeight: 5,
        maxWidth: 5,
        minWidth: 5,
        emptySquares: EmptySquareOptions.TWO,
        doubleCount: DoubleCounts.NONE,
        doubleOption: DoubleOptions.ALLLETTERS
    },
    {
        title: 'Classic',
        difficulty: Difficulty.STANDARD,
        description : 'Enough words to write a novel',
        maxHeight: 4,
        minHeight: 4,
        maxWidth: 4,
        minWidth: 4,
        emptySquares: EmptySquareOptions.NONE,
        doubleCount: DoubleCounts.NONE,
        doubleOption: DoubleOptions.CONSONANTS
    },
]
