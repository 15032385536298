
import { LETTER_CONFIG_EASY, LETTER_CONFIG_STANDARD, LETTER_CONFIG_HARD, LETTER_CONFIG_IMPOSSIBLE, LetterConfig} from './letter_config'
import { isVowel, isConsonant, ALL_LETTERS, VOWELS, EASY_CONSONANTS, HARD_CONSONANTS, STANDARD_CONSONANTS, CONSONANTS } from './word_tools'
import { Difficulty, GridOptions, GridTemplate, TemplateStrings, EmptySquareOptions, DoubleCounts, DoubleOptions } from './base_types';
import { gameConfigurationsStandard, gameConfigurationsDaily } from './game_config';

import seedRandom from 'seedrandom'


var rng = seedRandom('hello.');

export type SpecialCharacterOptions = {
  wildAny: boolean,
  wildVowel: boolean,
  wildConsonant: boolean,
  free: boolean,
  reset: boolean,
  infiniteUse: boolean
}

const RAND_PERCENT_SEVERAL = .15;
const RAND_PERCENT_MANY = .30;
const RAND_PERCENT_CHAOS = .40;


export const EMPTY_CHAR = '@'
const VISITED_CHAR = '#'

/*
  FREE - pretty useless in corners or on the side, maybe useful in the middle.  Seems like a big jump.
  INFINITE - Not nearly as powerful as wild.  Better on a vowel, hard to use on many (but not all) consonants.
*/

export enum SpecialCharacters {
  WILD_ANY = '!',
  WILD_VOWEL = '$',
  WILD_CONSONANT = '%',
  FREE = '^',
  RESET = '&',
  INFINITE_USE = '('
}

const GRID_SIZE_LARGE = 37

// Weighted towards vowel and consonant wilds
const WILD_RANDOM_WEIGHTS = [
  SpecialCharacters.WILD_ANY, SpecialCharacters.WILD_CONSONANT, SpecialCharacters.WILD_VOWEL, SpecialCharacters.WILD_CONSONANT, SpecialCharacters.WILD_VOWEL
]

const SPECIAL_RANDOM_WEIGHTS = [
  SpecialCharacters.RESET, SpecialCharacters.INFINITE_USE, SpecialCharacters.INFINITE_USE
]


function randomArrayElement(arr : any[]): any {
  return arr[Math.floor(Math.random() * arr.length)]
}

function getLetter(letterConfig: any): string {
  let randomIndex = Math.floor(Math.random() * letterConfig.length)
  let letter = letterConfig[randomIndex]
  // Remove the element we added
  letterConfig.splice(randomIndex, 1)
  if(!letter) console.error('Unexpected letter generated: ', letter)
  return letter
}

export class GridConfiguration implements GridOptions {
  width: number
  height: number
  difficulty: Difficulty;
  title : string
  emptySquares: EmptySquareOptions;
  readonly useWilds = false
  readonly useSpecials: boolean = false
  seed : number
  doubleCount: DoubleCounts
  readonly doubleOption: DoubleOptions
  template: GridTemplate = []

  constructor(randomSeed: number, dailyPuzzle: Boolean = false){
    function randomArrayElement(arr : any[]): any {
      return arr[Math.floor(Math.random() * arr.length)]
    }
  
    seedRandom(randomSeed, { global: true });

    let configuration = dailyPuzzle ? randomArrayElement(gameConfigurationsDaily) : randomArrayElement(gameConfigurationsStandard)
    // let configuration = gameConfigurations[0]

    this.difficulty = configuration.difficulty
    this.title = configuration.title

    this.width = Math.floor(Math.random() * (configuration.maxWidth - configuration.minWidth) + configuration.minWidth),
    this.height = Math.floor(Math.random() * (configuration.maxHeight - configuration.minHeight) + configuration.minHeight),

    this.emptySquares = configuration.emptySquares
    this.doubleCount = configuration.doubleCount
    this.doubleOption = configuration.doubleOption

    if(configuration.template) this.template = configuration.template

    this.seed = randomSeed
  }

  // Used to deterministically change seed when no words are found
  public incrementSeed(): void {
    this.seed = this.seed + 1;
  }

  // Fallback grid in case no grids with words are found during grid generation
  public setFallbackEasyGrid(){
    this.width = 5
    this.height = 5
    this.difficulty = Difficulty.EASY
    this.emptySquares = EmptySquareOptions.NONE
    this.doubleCount = DoubleCounts.NONE
  }
}


export class WordGrid {
  grid: string[][]

  constructor(grid: string[][]){
    this.grid = grid;
  }

  // A - Any
  // V - Vowel
  // X - Empty
  // C - Consonant
  // D - Double

  static getTemplateLetter(c : string, d: Difficulty, letterConfig: string[]): string{
    if(c == TemplateStrings.ANY_LETTER){
      // let letterConfig = LetterConfig.getLetterConfig(d)
      // return randomArrayElement(letterConfig)
      return getLetter(letterConfig)
    }
    else if (c == TemplateStrings.VOWEL) {
      return randomArrayElement(VOWELS)
    }
    else if ( c == TemplateStrings.CONSONANT) {
      return randomArrayElement(WordGrid.getTemplateConstants(d))
    }
    else if ( c == TemplateStrings.DOUBLE) {
      let diff_consonants = WordGrid.getTemplateConstants(d)
      let newDouble = randomArrayElement([
        randomArrayElement(VOWELS) + randomArrayElement(diff_consonants),
        randomArrayElement(diff_consonants) + randomArrayElement(VOWELS),
      ])
      newDouble = newDouble[0] + newDouble[1].toLowerCase()
      return newDouble
    }
    else if (c == TemplateStrings.EMPTY) {
      return EMPTY_CHAR
    }
    console.error('Unknown character found in template: ' + c)
    return c
  }

  static getTemplateConstants(difficulty : Difficulty): string[] {
    if(difficulty === Difficulty.EASY) return EASY_CONSONANTS
    else if (difficulty === Difficulty.STANDARD) return STANDARD_CONSONANTS
    return HARD_CONSONANTS
  }

  static getGridFromTemplate(template: string[], difficulty: Difficulty) : WordGrid{
    const mainGrid: string[][] = []
    const rowLength = template[0].length
    let letterConfig = LetterConfig.getLetterConfig(difficulty)

    template.forEach((templateRow) => {
      if(templateRow.length != rowLength) {
        console.error('Template does not have uniform row length. Expected ' + rowLength + ' found: ' + templateRow.length)
        return
      }
      const gridRow : string[] = []

      templateRow.split('').forEach((singleChar)=> {
        gridRow.push(WordGrid.getTemplateLetter(singleChar, difficulty, letterConfig))
      })

      mainGrid.push(gridRow)
    })


    return new WordGrid(mainGrid)
  }

  static getRandomGrid(options: GridOptions): WordGrid {
    if(options.seed){
      seedRandom(options.seed, { global: true });
    }

    const useTemplate = options.template ? options.template.length > 0 : false;

    if(useTemplate){
      // @ts-ignore
      return WordGrid.getGridFromTemplate(options.template, options.difficulty)
    }

    const mainGrid = []
    const letterConfigArray = LetterConfig.getLetterConfigWithDoubles(options.difficulty, options.doubleCount, options.doubleOption);

    for(let i = 0; i < options.height; i++){
      const row = []
      for(let j = 0; j < options.width; j++) {
        row.push(getLetter(letterConfigArray))
      }
      mainGrid.push(row)
    }

    WordGrid.addEmptyBlocks(mainGrid, options.emptySquares)
    if(options.useWilds) WordGrid.addWildBlocks(mainGrid) 
    if(options.useSpecials) WordGrid.addSpecialBlocks(mainGrid)
    

    return new WordGrid(mainGrid)
  }

  private static addWildBlocks(mainGrid: string[][]){
    const gridSize = mainGrid.length * mainGrid[0].length;
    const numWildBlocks = gridSize < GRID_SIZE_LARGE ? 1 : 2;
    for(let i = 0; i < numWildBlocks; i++) {
      WordGrid.addBlockToGridRandomPosition(
        mainGrid, 
        randomArrayElement(WILD_RANDOM_WEIGHTS)
      )  
    }
  }

  private static addSpecialBlocks(mainGrid: string[][]): void {
    const gridSize = mainGrid.length * mainGrid[0].length;
    const numSpecialBlocks = gridSize < GRID_SIZE_LARGE ? 1 : 2;
    for(let i = 0; i < numSpecialBlocks; i++) {

      let newChar = randomArrayElement(SPECIAL_RANDOM_WEIGHTS)
      let modifier = newChar == SpecialCharacters.INFINITE_USE ? true : false;

      WordGrid.addBlockToGridRandomPosition(
        mainGrid, 
        newChar,
        modifier
      )  
    }
  }

  private static addBlockToGridRandomPosition(mainGrid: string[][], char: string, append = false) : void {
    let blockAdded = false
    let failSafe = 0;
    while (!blockAdded) {
      failSafe++;
      const row = randomArrayElement(mainGrid)
      const rIndex = Math.floor(Math.random() * row.length)
      const existingChar = row[rIndex]
      if(existingChar != EMPTY_CHAR && !WordGrid.isSpecialChar(existingChar)){
        if(append) row[rIndex] = char + row[rIndex] 
        else row[rIndex] = char
        blockAdded = true
      }
      if(failSafe > 10000) {
        console.error('Error while adding block to grid - failsafe counter triggered.', mainGrid)
        break;
      }
    }
  }

  public static isWildChar(char: string) : boolean {
    if( char.charAt(0) === SpecialCharacters.WILD_ANY || 
        char.charAt(0) === SpecialCharacters.WILD_CONSONANT || 
        char.charAt(0) === SpecialCharacters.WILD_VOWEL) return true
    
    return false
  }

  public static isSpecialChar(char: string) : boolean {
    for(const special of Object.values(SpecialCharacters)){
      if(char.charAt(0) === special) return true
    }
    return false
  }

  private static addEmptyBlocks(mainGrid: string[][], emptyOption : EmptySquareOptions): void { 

    if(emptyOption === EmptySquareOptions.NONE) return

    let numBlocks = 0;
    if(emptyOption === EmptySquareOptions.ONE) numBlocks = 1;
    if(emptyOption === EmptySquareOptions.TWO) numBlocks = 2;
    if(emptyOption === EmptySquareOptions.THREE) numBlocks = 3;

    const height = mainGrid.length
    const width = mainGrid.length

    if(emptyOption === EmptySquareOptions.SEVERAL) numBlocks = height*width*RAND_PERCENT_SEVERAL
    if(emptyOption === EmptySquareOptions.MANY) numBlocks = height*width*RAND_PERCENT_MANY
    if(emptyOption === EmptySquareOptions.CHAOS) numBlocks = height*width*RAND_PERCENT_CHAOS

    while (numBlocks > 0) {
      const row = randomArrayElement(mainGrid)
      const rIndex = Math.floor(Math.random() * row.length)
      if(row[rIndex] != EMPTY_CHAR){
        row[rIndex] = EMPTY_CHAR
        numBlocks--
      }
    }
  }

  public validateWord(word: string) : boolean {
    let wordToCheck = word.toUpperCase()
    for(let i=0; i< this.grid.length; i++){
      for(let j = 0; j < this.grid[i].length; j++){
        let workingGrid = this.cloneGrid()
        if(this.checkSquare(i, j, wordToCheck, workingGrid)) return true
      }
    }

    return false;
  }

  private checkSquare(height: number, width: number, word : string, workingGrid: string[][]) : boolean {
    if(height < 0 || height >= workingGrid.length) return false
    if(width < 0 || width >= workingGrid[height].length) return false
    
    // Uppercase due to some double letters having upper and lower case
    const currentChar = workingGrid[height][width].toUpperCase()

    if(currentChar == EMPTY_CHAR) return false
    if(currentChar == VISITED_CHAR) return false

    let nextWord = ''

    if(WordGrid.isSpecialChar(currentChar)) {
      if(currentChar == SpecialCharacters.WILD_ANY){
        if(word.length === 1) return true
        nextWord = word.substring(1)
      }
      else if (currentChar == SpecialCharacters.WILD_VOWEL){
        if(!isVowel(word.charAt(0))) return false
        if(word.length === 1) return true
        nextWord = word.substring(1)
      }
      else if (currentChar == SpecialCharacters.WILD_CONSONANT){
        if(!isConsonant(word.charAt(0))) return false
        if(word.length === 1) return true
        nextWord = word.substring(1)
      }
      else if (currentChar == SpecialCharacters.FREE){
        nextWord = word;
      }
      else if (currentChar == SpecialCharacters.RESET){
        workingGrid = this.cloneGrid()
        nextWord = word;
      }
      else if (currentChar.charAt(0) == SpecialCharacters.INFINITE_USE){
        // Infinite is done by appending, so we want to compare to the second char
        if(word.charAt(0) != currentChar.charAt(1)) return false
        if(word.length === 1) return true
        nextWord = word.substring(1)
      }
      else {
        console.error('Unexpected special char found in checkSquare: ' + currentChar)
      }
    }
    else if(currentChar.length == 1) {
      if(word.charAt(0) != currentChar) return false
      if(word.length === 1) return true
      nextWord = word.substring(1)
    }
    else if (currentChar.length == 2) {
      if(word.length < 2) return false
      if((word.charAt(0) + word.charAt(1)) != currentChar) return false
      if(word.length === 2) return true
      nextWord = word.substring(2)
    }
    else {
      console.error('Grid word has length > 2.  Not expected')
    }

    // For infinite use, don't mark as visited
    if(currentChar.charAt(0) != SpecialCharacters.INFINITE_USE) workingGrid[height][width] = VISITED_CHAR

    // Check in all directions
    let top = this.checkSquare(height + 1, width, nextWord, workingGrid)
    let topRight = this.checkSquare(height + 1, width + 1, nextWord, workingGrid)
    let topLeft = this.checkSquare(height + 1, width - 1, nextWord, workingGrid)

    let left = this.checkSquare(height, width - 1, nextWord, workingGrid)
    let right = this.checkSquare(height, width + 1, nextWord, workingGrid)

    let bottom = this.checkSquare(height - 1, width, nextWord, workingGrid)
    let bottomRight = this.checkSquare(height - 1, width + 1, nextWord, workingGrid)
    let bottomLeft = this.checkSquare(height - 1, width - 1, nextWord, workingGrid)

    let current = false
    if(currentChar.charAt(0) === SpecialCharacters.INFINITE_USE) current = this.checkSquare(height, width, nextWord, workingGrid)

    let result = top || topRight || topLeft || left || right || bottom || bottomRight || bottomLeft || current
    
    if(!result) {
      workingGrid[height][width] = currentChar;
    }
  
    return result
  }

  public rotate90(): void {
    let newGrid = []
    let width = this.grid[0].length

    for(let i=0; i<width; i++){
      let row = []
      for(let j=this.grid.length - 1 ; j>=0; j-- ){
        row.push(this.grid[j][i])
      }
      newGrid.push(row)
    }
    this.grid = newGrid
  }

  public cloneGrid(): string[][] {
    let newGrid = []
    for(let i=0; i < this.grid.length;i++){
      newGrid.push(([] as string[]).concat(this.grid[i]))
    }
    // Convert to uppercase for purposes of comparison
    for(let i=0;i<newGrid.length;i++){
      for(let j=0;j<newGrid.length;j++){
        if(newGrid[i][j]) newGrid[i][j] = newGrid[i][j].toUpperCase();
      }
    }
    return newGrid
  }

  public getGrid(): string[][] {
    return this.grid
  }
}



