

<template>
  <nav class="navbar navbar-expand-lg bg-light">
    <div class="container">
      <router-link class="navbar-brand" to="/">Word Marsh</router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div class="navbar-nav">
          <router-link class="nav-link" to="/about">About</router-link>
          <router-link class="nav-link" to="/achievements">Achievements</router-link>
           <router-link class="nav-link" to="/puzzle">Standard</router-link>
           <router-link class="nav-link" to="/daily">Daily Puzzle</router-link>
          <router-link class="nav-link" to="/custom">Custom Game</router-link>
          <!-- <router-link class="nav-link" to="/multiplayer">Multiplayer</router-link> -->
          <router-link class="nav-link" to="/traditional">Traditional</router-link>
          <!-- <router-link class="nav-link" to="/keyboard">Keyboard</router-link> -->
        </div>
      </div>
    </div>
  </nav>
  <router-view/>
</template>

<style lang="scss">
@import "~bootstrap/scss/bootstrap";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}


nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}


@media all and (display-mode: fullscreen) {
  nav {
    display: none !important;
  }
}

.text-sm {
  font-size: .85rem;
}

.word-list {
  span:last-child {
    color: blue;
  }
}
</style>
