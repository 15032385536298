import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CustomGameView from '../views/CustomGameView.vue'
import MultiplayerView from '../views/MultiplayerGameView.vue'
import TraditionalGameView from '../views/TraditionalGameView.vue'
import PuzzleView from '../views/PuzzleView.vue'
import KeyboardFullDemo from '../views/KeyboardFullDemoView.vue'
import AchievementsView from '../views/AchievementsView.vue'
import DailyPuzzleView from '../views/DailyPuzzleView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: PuzzleView
  },
  {
    path: '/about',
    name: 'about',
    component: HomeView
  },
  {
    path: '/multiplayer',
    name: 'multiplayer',
    component: MultiplayerView
  },
  {
    path: '/traditional',
    name: 'traditional',
    component: TraditionalGameView
  },
  {
    path: '/custom/:gameOptions?',
    name: 'custom',
    component: CustomGameView
  },
  {
    path: '/puzzle',
    name: 'puzzle',
    component: PuzzleView
  },
  {
    path: '/daily',
    name: 'daily',
    component: DailyPuzzleView
  },
  {
    path: '/keyboard',
    name: 'keyboard',
    component: KeyboardFullDemo
  },
  {
    path: '/achievements',
    name: 'achievements',
    component: AchievementsView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
